<template>
  <VCard :collapsed="!isExpanded">
    <template #title>
      {{ $t("app.advanced_search") }}
    </template>

    <template #title-actions>
      <div class="flex items-center">
        <VSwitch v-model="isExpanded" hide-value />

        {{ isExpanded ? $t("app.hide") : $t("app.show") }}
      </div>
    </template>

    <template #content>
      <div class="grid gap-2">
        <slot />
      </div>
    </template>

    <template #actions>
      <div class="flex justify-between items-center">
        <VButton
          class="btn-primary-soft inline-block"
          :label="$t('app.export_results')"
          @click="$emit('export')"
        />

        <VButton
          class="btn-primary mr-2"
          :label="$t('app.search')"
          @click="$emit('search')"
        />
      </div>
    </template>
  </VCard>
</template>

<script>
import { ref } from "vue";
// Components
import VCard from "@/components/VCard";
import VButton from "@/components/VButton";
import VSwitch from "@/components/inputs/VSwitch";

export default {
  components: {
    VCard,
    VButton,
    VSwitch
  },
  emits: ["export", "search"],
  setup() {
    // Data
    const isExpanded = ref(true);

    return {
      isExpanded
    };
  }
};
</script>
