<template>
  <div>
    <slot :id="id" name="label">
      <label v-if="label" :for="id" class="form-label">
        {{ label }}
      </label>
    </slot>

    <div class="relative w-full">
      <div
        class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 border text-gray-600 dark:bg-dark-1 dark:border-dark-4"
      >
        <CalendarIcon class="w-4 h-4" />
      </div>
      <Litepicker
        :id="id"
        :value="value"
        autocomplete="off"
        :readonly="readonly"
        :disabled="readonly"
        :options="{
          dropdowns: {
            minYear: null,
            maxYear: null,
            months: true,
            years: true
          }
        }"
        class="form-control pl-12"
        @update:modelValue="onUpdate"
      />
    </div>

    <VErrors :errors="errors" />
  </div>
</template>

<script>
import { inject, computed, onMounted, ref } from "vue";
// Mixins
import InputMixin from "@/mixins/InputMixin";
// Constants
import dateFormats from "@/constants/dateFormats";

export default {
  mixins: [InputMixin],
  emits: ["update:modelValue"],
  setup(props, context) {
    // Misc
    const moment = inject("moment");

    // Data
    const hasMounted = ref(false);

    // Computed
    const value = computed(() => {
      return props.modelValue
        ? moment(props.modelValue).format(dateFormats.HUMAN_DATE_FORMAT)
        : "";
    });

    // Methods
    const onUpdate = date => {
      if (!hasMounted.value) {
        return;
      }

      const value = moment(new Date(date)).format("YYYY-MM-DD");
      context.emit("update:modelValue", value);
    };

    onMounted(() => {
      hasMounted.value = true;
    });

    return {
      value,
      onUpdate
    };
  }
};
</script>
