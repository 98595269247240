<template>
  <div class="grid gap-4">
    <VCard :title-class="titleClass">
      <template #image>
        <slot name="image">
          <VImage :name="name" class="mr-3" :color="item?.colour" />
        </slot>
      </template>

      <template #title>
        <slot name="name">
          {{ name }}
        </slot>
      </template>

      <template #subtitles>
        <slot name="subtitles" />
      </template>

      <template #title-actions>
        <slot name="status">
          <VChip
            :text="item?.status ?? ''"
            :class="getStatusColor(item?.status)"
          />
        </slot>
      </template>

      <template v-if="Object.keys(attributes).length > 0" #content>
        <div class="grid grid-cols-4 gap-2">
          <div
            v-for="(value, key) in data"
            :key="key"
            class="flex items-center gap-2"
          >
            <div>{{ key }}:</div>

            <div class="font-medium">
              {{ value }}
            </div>
          </div>
        </div>
      </template>
    </VCard>

    <slot name="registrations">
      <VAlert
        v-if="!report?.registrations?.length"
        :text="$t('app.no_registrations_found', 2)"
      />

      <Registrations
        v-if="report?.registrations?.length"
        class="mt-2"
        :registrations="report?.registrations"
      />
    </slot>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "@/store";
import { useI18n } from "vue-i18n";
// Composables
import useColor from "@/composables/useColor";
// Components
import VCard from "@/components/VCard";
import Registrations from "./trainings/Registrations";
import VImage from "@/components/VImage";
import VChip from "@/components/VChip";
import VAlert from "@/components/VAlert";

export default {
  components: {
    VCard,
    VImage,
    VChip,
    Registrations,
    VAlert
  },
  props: {
    report: {
      type: Object,
      default: () => ({})
    },
    reportClass: {
      type: String,
      default: ""
    },
    attributes: {
      type: Object,
      default: () => ({})
    },
    resource: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    // Misc
    const store = useStore();
    const { t } = useI18n();

    // Composables
    const { getStatusColor } = useColor();

    // Computed
    const userLang = computed(() => store.getters["user/language"]);
    const item = computed(() => {
      if (!props.resource) {
        return props.report;
      }

      return props.report?.[props.resource] ?? {};
    });

    const name = computed(() => item.value?.name?.[userLang.value]?.name);

    const titleClass = computed(() => {
      let titleClass = `${props.reportClass} rounded-t-md`;

      if (Object.keys(props.attributes).length === 0) {
        titleClass = `${titleClass} rounded-b-md`;
      }

      return titleClass;
    });

    const data = computed(() => {
      const resource = item.value;
      const attributes = props.attributes;

      return Object.keys(attributes).reduce((acc, cv) => {
        const key = Object.keys(resource).find(key => key === cv);
        const value = resource[key];

        if (!key) {
          return acc;
        }

        if (["duration"].includes(key)) {
          return {
            ...acc,
            // eslint-disable-next-line
            [attributes[key]]: value && resource.duration_type ? `${value} ${resource?.duration_type}` : "-"
          };
        }

        if (["credits", "credits_completed", "credits_offered"].includes(key)) {
          return {
            ...acc,
            [attributes[key]]: new Intl.NumberFormat().format(value)
          };
        }

        if (["hours_completed", "hours_offered"].includes(key)) {
          return {
            ...acc,
            [attributes[key]]: getValue(value)
          };
        }

        return {
          ...acc,
          [attributes[key]]: value
        };
      }, {});
    });

    // Methods
    const getValue = (time = "") => {
      const [hours, minutes] = time.split(/[hm]/).map(x => parseInt(x));

      // eslint-disable-next-line
      const hoursLabel = `${new Intl.NumberFormat().format(hours)} ${t("app.hours", 2).toLowerCase()}`;
      // eslint-disable-next-line
      const minutesLabel = `${new Intl.NumberFormat().format(minutes)} ${t("app.minutes", 2).toLowerCase()}`;

      if (hours && minutes) {
        return `${hoursLabel} ${minutesLabel}`;
      }

      if (hours) {
        return hoursLabel;
      }

      if (minutes) {
        return minutesLabel;
      }

      return "-";
    };

    return {
      titleClass,
      name,
      item,
      data,
      // useColor
      getStatusColor
    };
  }
};
</script>
